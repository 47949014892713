$unarmsBlue: #009edb;
$unarmsDarkGrey: #333333;
$unarmsGrey: #4d4d4d;
$unarmsLightGrey: #f2f2f2;
$unarmsFooterGrey: #c4c4c4;
$unarmsRed: #c63629;
$unarmsBeige: #e5d8bb;
$unarmsBorderGrey: rgba(128, 128, 128);
$unarmsDividerGrey: #5b5b5b;

// arDominionB5Plugin overrides
$primary: $unarmsBlue;
$secondary: $unarmsGrey;
$body-bg: #fff;
$link-color: $primary;
$font-family-sans-serif: "Roboto", sans-serif;

// Bootstrap 5 carousel overrides
$carousel-indicator-width: 14px;
$carousel-indicator-height: 14px;
$carousel-indicator-spacer: 7px;
$carousel-indicator-opacity: 1;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": #198754,
  "info": $unarmsBlue,
  "warning": #ffc107,
  "danger": $unarmsRed,
  "light": $unarmsLightGrey,
  "dark": #212529,
  "unarms-red": $unarmsRed,
  "unarms-light-grey": $unarmsLightGrey,
  "unarms-dark-grey": $unarmsDarkGrey,
  "unarms-footer-grey": $unarmsFooterGrey,
  "border-grey": $unarmsBorderGrey,
);
