.atom-btn-secondary {
  font-weight: bold !important;

  &:hover,
  &:focus {
    background-color: $unarmsLightGrey;
    border-color: $unarmsLightGrey;
    color: $unarmsDarkGrey;
  }
}

#brand-bar {
  font-size: 14px;
  line-height: 1.75rem;
}

#logos {
  padding-top: 2rem;
  padding-bottom: 2rem;

  a:hover {
    text-decoration: none;
  }

  img {
    max-height: 4.5rem;
  }

  #unarms-agency {
    padding-top: 1rem;
    color: $secondary !important;
  }
}

#sidebar {
  order: 1;

  #browse-by {
    margin-top: 1rem;
  }
}

@include media-breakpoint-up(md) {
  #sidebar {
    order: 0;

    #browse-by {
      margin-top: 0;
    }    
  }
}

.clipboard-count {
  background-color: $secondary !important;
}

body {
  .navbar-dark .navbar-nav .nav-link {
    &:hover, &:active, &:focus {
      color: $primary;
      background-color: $white;
    }
  }

  #content,
  #context-menu,
  #treeview-content li,
  #breadcrumb li,
  #wrapper table,
  .card-view-result {
    color: $unarmsDarkGrey;

    a {
      color: black !important;
      text-decoration: underline dotted 0.05rem;
      text-underline-position: under;
    }

    .bg-dark-25 {
      background-color: rgba(0, 0, 0, 0.25);
    }

    .bg-dark-50 {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .bg-dark-75 {
      background-color: rgba(0, 0, 0, 0.75);
    }
  }

  #main-column 

  #treeview-menu li .nav-link {
    color: black !important;
    text-decoration: underline dotted 0.05rem;
    text-underline-position: under;
  }
}

body.home {
  #content {
    border: none;
  }

  .carousel-item {
    text-align: center;
    padding-bottom: 10rem;

    a img {
      max-height: 100%;
    }
  }

  .carousel-caption {
    width: 100%;
    height: 10rem;
    left: 0;
    padding: 2.5rem 1.5rem 2.5rem 1.5rem;
    text-align: left;

    a {
      text-decoration: none !important;
    }
  }

  @include media-breakpoint-up(sm) {
    .carousel-item {
      padding-bottom: 7rem;
    }

    .carousel-caption {
      height: 7rem;
    }
  }

  @include media-breakpoint-up(lg) {
    .carousel-item-image > img {
      height: 100%;
    }
  }

  @include media-breakpoint-up(xl) {
    .carousel-item {
      height: 50rem;
    }
  }

  .carousel-indicators {
    button {
      border: 0.15rem solid #ced4da;
      border-radius: 50%;
      background-color: white !important;
    }

    button.active {
      background-color: black !important;
    }
  }

  #tiled-img-links a {
    text-decoration: none !important;
  }
}

footer {
  .footer-main ul {
    display: flex;
    color: $unarmsFooterGrey;
  }

  .footer-main ul li a {
    color: $unarmsFooterGrey !important;
    text-transform: uppercase;
  }

  .footer-main i {
    color: $unarmsFooterGrey;
  }

  #footer-logo-svg {
    display: block;
    color: white !important;
  }

  #social {
    ul {
      display: flex;
      align-items: flex-end;

      li {
        list-style-type: none;
        padding: 1rem 1rem 0 0;
      }

      li a:hover {
        text-decoration: none;
      }
    }
  }

  @include media-breakpoint-up(md) {
    #social {
      ul {
        align-items: center;

        li {
          padding: 0 0 0 2rem;
        }

        li:last-child {
          border-right: $unarmsBorderGrey 1px solid;
          padding-right: 2rem;
        }
      }
    }
  }

  #donate ul {
    li {
      list-style-type: none;
      padding: 1rem 0 0 1rem;
    
      a {
        display: inline-block;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1.27px;
        line-height: 12px;
        margin-bottom: 0px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 9px;
        color: $unarmsDarkGrey !important;
        font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: bold !important;

        &:hover,
        &:focus {
          background-color: $unarmsLightGrey;
          border-color: $primary;
          color: $unarmsLightGrey;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    #donate ul li {
      padding: 0 0 0 2rem;
    }
  }

  #un-links ul {
    display: inline-block !important;

    li {
      a {
        color: $white !important;
        font-size: 0.8rem;
        font-weight: bold;
        letter-spacing: 0.77px;
      }

      a:hover {
        text-decoration: none;
      }
    }
  }

  @include media-breakpoint-up(md) {
    #un-links {
      #un-links-md-right {
        float: right !important;
      }

      ul {
        display: flex !important;

        li {
          list-style-type: none;
          padding: 0 1rem 0 1rem;
          border-right: $unarmsBorderGrey 3px solid;
          line-height: 0.8rem;
        }

        li:last-child {
          border-right: none;
          padding-right: 0;
        }
      }
    }
  }

  .pull-right {
    float: right !important;
  }

  .divider {
    border-top: $unarmsDividerGrey solid 1px;
    margin-bottom: 0.5rem;
  }

  img {
    max-height: 3.5rem;
  }
}

h1,
h2,
h3,
h4,
body.index #content h2 button,
body.index #atom-digital-object-carousel .accordion-button {
  font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1.1;
}

#sidebar .accordion-button span {
  text-transform: uppercase;
}

#sidebar .card h2,
.accordion-button,
.atom-section-header > * {
  position: relative;
  padding-bottom: $spacer * 1.5 !important;

  &::before {
    content: "";
    position: absolute;
    bottom: $spacer;
    width: $spacer * 3;
    border-top: $primary solid 3px;
  }
}

.accordion-button::after, .atom-section-header a::after {
  margin-top: $spacer * 0.5;
}
